import React, { useEffect } from "react"
import { connect } from "react-redux"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { StyledBlock, StyledContainer, StyledH2 } from "../components/styled"

const ImprintPage = ({ changeHeaderColor, defaultHeaderColor }: any) => {
	useEffect(() => {
		changeHeaderColor(defaultHeaderColor)
	}, [])
	return (
		<Layout>
			<SEO title="Imprint" />
			<StyledBlock subPage>
				<StyledContainer>
					<StyledH2>Imprint</StyledH2>
					<p>Informationspflicht laut § 5 TMG.</p>
					<h2>Raise</h2>
					<p>
						Grossbeerenstrasse 11
						<br />
						10963 Berlin
						<br />
						Germany
					</p>
					<p>
						E-Mail: <a href="mailto:support@raise-app.com">support@raise-app.com</a>
					</p>
					<h3>Aufsichtsbehörde</h3>
					<p>Berliner Beauftragte für Datenschutz und Informationsfreiheit</p>
					<h3>Webseite der Aufsichtsbehörde</h3>
					<p>
						<a href="https://www.datenschutz-berlin.de/">https://www.datenschutz-berlin.de/</a>
					</p>
					<h3>Anschrift der Aufsichtsbehörde</h3>
					<p>Friedrichstr. 219 10969 Berlin</p>
				</StyledContainer>
			</StyledBlock>
		</Layout>
	)
}

const mapStateToProps = ({ defaultHeaderColor }: any) => ({ defaultHeaderColor })

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeHeaderColor: (payload: boolean) => dispatch({ type: `CHANGE_HEADER_COLOR`, payload }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImprintPage)
